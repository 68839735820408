<template>
  <div>
    <div
      v-if="userRole === 'admin'"
      class="create-project-page pb-8 bg-purple-bg"
    >
      <button
        class="text-sm text-black mb-8 cursor-pointer"
        @click="
          () => {
            $router.push('/manage/projects');
          }
        "
      >
        &lt;&lt;&nbsp;{{ $t('BACK_BTN') }}
      </button>
      <section class="text-3xl mb-20 font-semibold text-black">{{ $t('PROJECT_EDIT_TITLE') }}</section>
      <ValidationObserver ref="observer">
        <form @submit.prevent="onSubmitForm">
          <section class="project-info">
            <ProjectViewSection
              class="!mb-0"
              title="PROJECT_GENERAL_SECTION_TITLE"
              :dataSource="form"
              :columns="generalSection"
            >
              <div
                slot="project_img"
                value=""
                class="flex items-center"
              >
                <ValidationProvider
                  rules=""
                  v-slot="{ errors }"
                  name="PROJECT_IMG"
                >
                  <div class="flex items-center">
                    <BaseUploader
                      v-model="form.banner"
                      id="project_banner"
                      suggestSizeText=""
                    />
                    <div v-if="!form.banner">
                      <a
                        class="text-xl font-semibold"
                        target="_blank"
                        download
                        :href="projectDetail.banner_url"
                        >{{ projectDetail.banner_uploaded_name }}</a
                      >
                    </div>
                  </div>
                  <ErrorMessage
                    id="project_img"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <div
                slot="project_thumbnail"
                value=""
                class="flex items-center"
              >
                <ValidationProvider
                  rules=""
                  v-slot="{ errors }"
                  name="PROJECT_LISTING_IMG"
                >
                  <div class="flex items-center">
                    <BaseUploader
                      v-model="form.thumbnail"
                      id="project_thumbnail"
                      suggestSizeText=""
                    />
                    <div v-if="!form.thumbnail">
                      <a
                        class="text-xl font-semibold"
                        target="_blank"
                        download
                        :href="projectDetail.thumbnail_url"
                        >{{ projectDetail.thumbnail_uploaded_name }}</a
                      >
                    </div>
                  </div>
                  <ErrorMessage
                    id="project_thumbnail"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <div slot="name">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="PROJECT_NAME"
                >
                  <a-input
                    v-model="form.name"
                    :maxLength="100"
                    :placeholder="$t('PROJECT_MAX_CHARACTER_PLACEHOLDER', { max: 100 })"
                  />
                  <ErrorMessage
                    id="name"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <div slot="label_name">{{ $t('PROJECT_NAME_LABEL') }}&nbsp;(en)</div>
              <div slot="label_name_ja">{{ $t('PROJECT_NAME_LABEL') }}&nbsp;(ja)</div>
              <div slot="name_ja">
                <ValidationProvider
                  rules=""
                  v-slot="{ errors }"
                  name="PROJECT_NAME_JAPANESE"
                >
                  <a-input
                    v-model="form.name_ja"
                    :maxLength="100"
                    :placeholder="$t('PROJECT_MAX_CHARACTER_PLACEHOLDER', { max: 100 })"
                  />
                  <ErrorMessage
                    id="name_ja"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <div slot="description">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="PROJECT_DESCRIPTION"
                >
                  <Editor
                    v-model="form.description"
                    :maxLength="2400"
                  ></Editor>
                  <ErrorMessage
                    id="description"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <div slot="label_description">{{ $t('PROJECT_DESCRIPTION_LABEL') }}&nbsp;(en)</div>
              <div slot="label_description_ja">{{ $t('PROJECT_DESCRIPTION_LABEL') }}&nbsp;(ja)</div>
              <div slot="description_ja">
                <ValidationProvider
                  rules=""
                  v-slot="{ errors }"
                  name="PROJECT_DESCRIPTION_JAPANESE"
                >
                  <Editor
                    v-model="form.description_ja"
                    :maxLength="2400"
                  ></Editor>
                  <ErrorMessage
                    id="description_ja"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <div slot="chain">
                <a-select
                  :value="form.chain_id + (form.token_address ? `##${form.token_address}` : '')"
                  @change="onChainChange"
                  class="w-80"
                >
                  <span
                    slot="suffixIcon"
                    class="text-4xl text-black relative -top-3.5 left-2"
                    >▾</span
                  >
                  <a-select-option
                    v-for="chain in chains"
                    :value="chain.value"
                    :key="chain.value"
                  >
                    {{ chain.key }}
                  </a-select-option>
                </a-select>
              </div>
              <div slot="credit_payment">
                <a-checkbox
                  v-model="form.credit_payment"
                  >{{ $t('BUTTON_PIMENT_BUY') }}</a-checkbox
                >
              </div>
              <div slot="symbol">
                <a-input
                  v-model="form.symbol"
                  :maxLength="20"
                  :placeholder="$t('PROJECT_MAX_CHARACTER_PLACEHOLDER', { max: 20 })"
                />
              </div>
              <div slot="img">
                <ValidationProvider
                  rules=""
                  v-slot="{ errors }"
                  name="NFT_IMG"
                >
                  <div class="flex items-center">
                    <div class="flex items-center mr-4">
                      <BaseUploader
                        id="images-zip"
                        v-model="form.images"
                        :shouldLimitFileSize="false"
                        accept="application/x-zip-compressed, application/zip"
                        class=""
                      />
                      <div class="ml-4">*{{ $t('PROJECT_ZIP_ONLY') }}</div>
                    </div>

                    <div
                      v-if="!form.images"
                      class="truncate flex-1"
                    >
                      <a
                        class="text-xl font-semibold truncate"
                        target="_blank"
                        download
                        :href="projectDetail.nft_images_url"
                        >{{ projectDetail.image_uploaded_name }}</a
                      >
                    </div>
                  </div>

                  <ErrorMessage
                    id="nft-image"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <div
                slot="json"
                class="w-full"
              >
                <div class="flex justify-between w-full">
                  <div class="flex-1 mr-4">
                    <ValidationProvider
                      rules=""
                      v-slot="{ errors }"
                      name="CSV_UPLOAD"
                    >
                      <div class="flex items-center flex-wrap">
                        <div class="flex items-center mr-4">
                          <BaseUploader
                            id="images-json"
                            v-model="form.json"
                            :shouldLimitFileSize="false"
                            accept=".csv"
                          />
                          <div class="ml-4">*{{ $t('PROJECT_CSV_ONLY') }}</div>
                        </div>
                        <div
                          v-if="!form.json"
                          class="flex-1 truncate"
                        >
                          <a
                            class="text-xl font-semibold w-full"
                            target="_blank"
                            download
                            :href="projectDetail.nft_json_url"
                            >{{ projectDetail.json_uploaded_name }}</a
                          >
                        </div>
                      </div>
                      <ErrorMessage
                        id="nft-json"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <a
                    class="text-right underline relative top-4 cursor-pointer text-xs w-max"
                    :href="JSON_SAMPLE_URL"
                    target="_blank"
                    download
                  >
                    {{ $t('PROJECT_SAMPLE_DOWNLOAD_REF') }}
                  </a>
                </div>
              </div>
              <div slot="website">
                <ValidationProvider
                  rules="required|url"
                  v-slot="{ errors }"
                  name="WEBSITE"
                >
                  <a-input
                    placeholder="https://zaif-ino.com/"
                    v-model="form.website_url"
                  ></a-input>

                  <ErrorMessage
                    id="Website"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <div slot="twitter">
                <ValidationProvider
                  rules="required|url"
                  v-slot="{ errors }"
                  name="TWITTER"
                >
                  <a-input
                    placeholder="https://twitter.com/zaif_ino"
                    v-model="form.twitter_url"
                  ></a-input>

                  <ErrorMessage
                    id="twitter"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <div slot="address">
                <ValidationProvider
                  rules="required|checkTokenAddress"
                  v-slot="{ errors }"
                  name="OWNER_ADDRESS"
                >
                  <a-input
                    :placeholder="$t('PROJECT_OWNER_ADDRESS_PLACEHOLDER')"
                    v-model="form.owner_address"
                  ></a-input>

                  <ErrorMessage
                    id="owner_address"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </ProjectViewSection>
            <ProjectViewSection
              title="PROJECT_SETTING_SECTION_TITLE"
              :dataSource="form"
              :columns="settingSection"
              class="text-xl font-semibold !mb-0"
            >
              <div slot="reveal">
                <div class="flex text-xl font-semibold w-full">
                  <a-radio-group v-model="form.can_reveal">
                    <a-radio :value="true"> {{ $t('PROJECT_YES') }} </a-radio>
                    <a-radio :value="false"> {{ $t('PROJECT_NO') }} </a-radio>
                  </a-radio-group>
                </div>
                <div
                  v-if="form.can_reveal"
                  class="mt-8"
                >
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    name="REVEAL_IMG"
                  >
                    <BaseUploader
                      id="reveal_thumbnail_uploader"
                      v-model="form.reveal_thumbnail"
                    />
                    <ErrorMessage
                      id="reveal_thumbnail"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </div>
              <div slot="presale_remaining">
                <div slot="transfer_unsold_disabled">
                  <div class="font-medium text-lg mb-2">
                    {{ $t('PROJECT_REMAINING_ACTION_TEXT') }}
                  </div>
                  <div class="text-xl font-semibold w-full">
                    <a-radio-group v-model="form.transfer_unsold_disabled">
                      <a-radio :value="false"> {{ $t('PROJECT_YES') }} </a-radio>
                      <a-radio :value="true"> {{ $t('PROJECT_NO') }} </a-radio>
                    </a-radio-group>
                    <div
                      v-if="!form.transfer_unsold_disabled"
                      class="mt-4"
                    >
                      <ValidationProvider
                        :rules="{
                          required: true,
                          min_value: 1,
                          max_value: totalAmount ? totalAmount : Number.MAX_SAFE_INTEGER,
                        }"
                        v-slot="{ errors }"
                        name="NFT_SALES_VOLUME"
                      >
                        <InputNumberCustom
                          type="text"
                          v-model="form.target"
                          :decimals="0"
                          class="inline-block w-80"
                          :placeholder="$t('PROJECT_COMBINED_TARGET_LABEL')"
                          :error="errors[0]"
                        ></InputNumberCustom>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </ProjectViewSection>
            <ProjectViewSection
              title="PROJECT_PRESALE_SECTION_TITLE"
              :dataSource="form"
              :columns="presaleSectionFiltered"
              class="!mb-0"
            >
              <div
                slot="start"
                class="text-xl font-semibold"
              >
                <div class="flex items-center">
                  <ValidationProvider
                    :rules="{
                      required: !form.is_presale_tba,
                    }"
                    v-slot="{ errors }"
                    name="PRESALE_START_TIME"
                  >
                    <div class="flex items-center">
                      <BaseDatepicker
                        id="presale_start"
                        v-model="form.presale_start_time"
                        :disabled="form.is_presale_tba"
                      />
                      <div
                        v-if="form.presale_start_time"
                        class="ml-4"
                      >
                        {{ form.presale_start_time | toJST }}
                      </div>
                      <div class="ml-4">
                        <a-checkbox
                          :checked="form.is_presale_tba"
                          @change="form.is_presale_tba = !form.is_presale_tba"
                          >{{ $t('TBA') }}</a-checkbox
                        >
                      </div>
                    </div>

                    <ErrorMessage
                      id="presale_start"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </div>
              <div
                slot="end"
                class="text-xl font-semibold"
              >
                <div>
                  <ValidationProvider
                    :rules="{
                      required: !form.is_presale_tba,
                    }"
                    v-slot="{ errors }"
                    name="PRESALE_END_TIME"
                  >
                    <div class="flex items-center">
                      <BaseDatepicker
                        id="presale_end"
                        v-model="form.presale_end_time"
                        :disabled="form.is_presale_tba"
                      />
                      <div
                        v-if="form.presale_end_time"
                        class="ml-4"
                      >
                        {{ form.presale_end_time | toJST }}
                      </div>
                    </div>

                    <ErrorMessage
                      id="presale_end"
                      :error="preSaleTimeError ? $t(preSaleTimeError) : errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </div>
              <div
                slot="amount"
                class="text-xl font-semibold"
              >
                <ValidationProvider
                  :rules="{
                    required: !Number(form.publicsale_amount),
                    min_value: 0,
                    max_value: 30000,
                  }"
                  v-slot="{ errors }"
                  name="PRESALE_AMOUNT"
                >
                  <InputNumberCustom
                    type="text"
                    v-model="form.presale_amount"
                    :decimals="0"
                    class="inline-block w-80"
                    placeholder="0 ~ 30000"
                    :error="errors[0]"
                  ></InputNumberCustom>
                </ValidationProvider>
              </div>
              <div
                slot="presale_target"
                class="text-xl font-semibold"
                v-if="form.transfer_unsold_disabled"
              >
                <ValidationProvider
                  :rules="{
                    required: true,
                    min_value: 1,
                    max_value: form.presale_amount,
                  }"
                  v-slot="{ errors }"
                  name="PRESALE_TARGET"
                >
                  <InputNumberCustom
                    type="text"
                    v-model="form.presale_target"
                    :decimals="0"
                    class="inline-block w-80"
                    :error="errors[0]"
                  ></InputNumberCustom>
                </ValidationProvider>
              </div>
              <div
                slot="price"
                class="text-xl font-semibold"
              >
                <ValidationProvider
                  :rules="{
                    required: !form.is_presale_price_tba,
                  }"
                  v-slot="{ errors }"
                  name="PRESALE_PRICE"
                >
                  <div class="flex items-center">
                    <InputNumberCustom
                      type="text"
                      v-model="form.presale_price"
                      :decimals="18"
                      class="inline-block w-80"
                      :disabled="form.is_presale_price_tba"
                      :error="errors[0]"
                      placeholder="0 ~"
                    ></InputNumberCustom>
                    <div class="ml-2">
                      {{ symbol }}
                    </div>
                    <div class="ml-4">
                      <a-checkbox
                        :checked="form.is_presale_price_tba"
                        @change="form.is_presale_price_tba = !form.is_presale_price_tba"
                        >{{ $t('TBA') }}</a-checkbox
                      >
                    </div>
                  </div>
                </ValidationProvider>
              </div>

              <div
                slot="whitelist"
                class="w-full"
              >
                <ValidationProvider
                  rules=""
                  v-slot="{ errors }"
                  name="WHITELIST"
                >
                  <div class="flex justify-between">
                    <div class="flex flex-1 items-center mr-4">
                      <BaseUploader
                        id="whitelist-json"
                        v-model="form.whitelist"
                        :shouldLimitFileSize="false"
                        accept=".csv"
                      />
                      <div
                        v-if="!form.whitelist"
                        class="flex-1 truncate ml-4"
                      >
                        <a
                          class="text-xl font-semibold"
                          target="_blank"
                          download
                          :href="projectDetail.presale_whitelist_url"
                          >{{ projectDetail.whitelist_uploaded_name }}</a
                        >
                      </div>
                    </div>
                    <a
                      class="text-right underline relative top-4 cursor-pointer text-xs w-max"
                      :href="WHITELIST_SAMPLE_URL"
                      target="_blank"
                      download
                    >
                      {{ $t('PROJECT_SAMPLE_DOWNLOAD_REF') }}
                    </a>
                  </div>
                  <ErrorMessage
                    id="whitelist"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <div
                slot="max_purchasability"
                class="text-xl font-semibold"
              >
                <ValidationProvider
                  :rules="{
                    required: true,
                    min_value: 1,
                  }"
                  v-slot="{ errors }"
                  name="PRESALE_MAX_PURCHASABILITY"
                >
                  <InputNumberCustom
                    type="text"
                    v-model="form.presale_max_purchase"
                    :decimals="0"
                    class="inline-block w-80"
                    :error="errors[0]"
                    placeholder="0 ~"
                  ></InputNumberCustom>
                </ValidationProvider>
              </div>
            </ProjectViewSection>
            <ProjectViewSection
              title="PROJECT_PRESALE_RESULT_SECTION_TITLE"
              :columns="presaleStatistics"
              :dataSource="form"
              class="!mb-0"
            >
              <div
                slot="sold"
                class="text-xl font-semibold"
              >
                -
              </div>
              <div
                slot="revenue"
                class="text-xl font-semibold"
              >
                -
              </div>
              <div
                slot="available"
                class="text-xl font-semibold"
              >
                -
              </div>
            </ProjectViewSection>
            <ProjectViewSection
              title="PROJECT_PUBSALE_SECTION_TITLE"
              :dataSource="form"
              :columns="publicSaleSectionFiltered"
              class="!mb-0"
            >
              <div
                slot="start"
                class="text-xl font-semibold"
              >
                <div class="flex items-center">
                  <ValidationProvider
                    :rules="{ required: !form.is_publicsale_tba }"
                    v-slot="{ errors }"
                    name="PUBLIC_START_TIME"
                  >
                    <div class="flex items-center">
                      <BaseDatepicker
                        id="public_start"
                        v-model="form.publicsale_start_time"
                        :disabled="form.is_publicsale_tba"
                      />
                      <div
                        v-if="form.publicsale_start_time"
                        class="ml-4"
                      >
                        {{ form.publicsale_start_time | toJST }}
                      </div>
                      <div class="ml-4">
                        <a-checkbox
                          :checked="form.is_publicsale_tba"
                          @change="form.is_publicsale_tba = !form.is_publicsale_tba"
                          >{{ $t('TBA') }}</a-checkbox
                        >
                      </div>
                    </div>

                    <ErrorMessage
                      id="public_start"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </div>
              <div
                slot="end"
                class="text-xl font-semibold"
              >
                <div class="flex items-center">
                  <ValidationProvider
                    :rules="{ required: !form.is_publicsale_tba }"
                    v-slot="{ errors }"
                    name="PUBLIC_END_TIME"
                  >
                    <div class="flex items-center">
                      <BaseDatepicker
                        id="public_end"
                        v-model="form.publicsale_end_time"
                        :disabled="form.is_publicsale_tba"
                      />
                      <div
                        v-if="form.publicsale_end_time"
                        class="ml-4"
                      >
                        {{ form.publicsale_end_time | toJST }}
                      </div>
                    </div>

                    <ErrorMessage
                      id="public_start"
                      :error="publicSaleTimeError ? $t(publicSaleTimeError) : errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </div>
              <div
                slot="amount"
                class="text-xl font-semibold"
              >
                <ValidationProvider
                  :rules="{
                    required: !Number(form.presale_amount),
                    min_value: 0,
                    max_value: 30000,
                  }"
                  v-slot="{ errors }"
                  name="AMOUNT"
                >
                  <InputNumberCustom
                    type="text"
                    v-model="form.publicsale_amount"
                    :decimals="0"
                    class="inline-block w-80"
                    placeholder="0 ~ 30000"
                    :error="errors[0]"
                  ></InputNumberCustom>
                </ValidationProvider>
              </div>
              <div
                slot="publicsale_target"
                class="text-xl font-semibold"
                v-if="form.transfer_unsold_disabled"
              >
                <ValidationProvider
                  :rules="{
                    required: true,
                    min_value: 1,
                    max_value: form.publicsale_amount,
                  }"
                  v-slot="{ errors }"
                  name="PUBLIC_SALE_TARGET"
                >
                  <InputNumberCustom
                    type="text"
                    v-model="form.publicsale_target"
                    :decimals="0"
                    class="inline-block w-80"
                    :error="errors[0]"
                  ></InputNumberCustom>
                </ValidationProvider>
              </div>
              <div
                slot="price"
                class="text-xl font-semibold"
              >
                <ValidationProvider
                  :rules="{
                    required: !form.is_publicsale_price_tba,
                  }"
                  v-slot="{ errors }"
                  name="PRICE"
                >
                  <div class="flex items-center">
                    <InputNumberCustom
                      type="text"
                      v-model="form.publicsale_price"
                      :decimals="18"
                      placeholder="0 ~"
                      class="inline-block w-80"
                      :disabled="form.is_publicsale_price_tba"
                      :error="errors[0]"
                    ></InputNumberCustom>
                    <div class="ml-2">
                      {{ symbol }}
                    </div>
                    <div class="ml-4">
                      <a-checkbox
                        :checked="form.is_publicsale_price_tba"
                        @change="form.is_publicsale_price_tba = !form.is_publicsale_price_tba"
                        >{{ $t('TBA') }}</a-checkbox
                      >
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <div
                slot="max_purchasability"
                class="text-xl font-semibold"
              >
                <ValidationProvider
                  :rules="{
                    required: true,
                    min_value: 1,
                  }"
                  v-slot="{ errors }"
                  name="PUBLIC_MAX_PURCHASABILITY"
                >
                  <InputNumberCustom
                    type="text"
                    v-model="form.publicsale_max_purchase"
                    :decimals="0"
                    placeholder="0 ~"
                    class="inline-block w-80"
                    :error="errors[0]"
                  ></InputNumberCustom>
                </ValidationProvider>
              </div>
            </ProjectViewSection>
            <ProjectViewSection
              title="PROJECT_PUBSALE_RESULT_SECTION_TITLE"
              :dataSource="form"
              :columns="publicSaleStatistics"
              class="!mb-0"
            >
              <div
                slot="sold"
                class="text-xl font-semibold"
              >
                -
              </div>
              <div
                slot="revenue"
                class="text-xl font-semibold"
              >
                -
              </div>
              <div
                slot="available"
                class="text-xl font-semibold"
              >
                -
              </div>
            </ProjectViewSection>
            <ProjectViewSection
              title="PROJECT_FEE_SECTION_TITLE"
              :dataSource="form"
              :columns="feeSection"
            >
              <div
                slot="fee"
                class="text-xl font-semibold"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="FEE"
                  :rules="{
                    required: true,
                    max_value: 100,
                  }"
                >
                  <InputNumberCustom
                    type="text"
                    v-model="form.fee"
                    :decimals="2"
                    inputClass="ratio-input"
                    class="inline-block w-32"
                    placeholder="2.0"
                  ></InputNumberCustom
                  >&nbsp;%
                  <ErrorMessage
                    id="presale_end"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <div
                slot="dev_fee_rate"
                class="text-xl font-semibold"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="PROJECT_REFERRAL_FEE_LABEL"
                  :rules="{
                    required: true,
                    max_value: 100,
                  }"
                >
                  <InputNumberCustom
                    type="text"
                    v-model="form.dev_fee_rate"
                    :decimals="2"
                    inputClass="ratio-input"
                    class="inline-block w-32"
                    placeholder="0.2"
                  ></InputNumberCustom
                  >&nbsp;%
                  <ErrorMessage
                    id="presale_end"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </ProjectViewSection>
          </section>
          <div class="w-full text-center bg-purple-bg py-8">
            <button
              class="bg-primary text-white hover:opacity-60 text-2xl font-semibold py-2 px-12 rounded-xl"
              type="submit"
              :disabled="loading"
            >
              {{ $t('PROJECT_SAVE_BTN') }}
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div
      v-else
      class="mt-20 text-2xl font-bold text-center"
    >
      {{ $t('PROJECT_INVALID_ROLE_NOTIFICATION') }}
    </div>
  </div>
</template>

<script>
import InputNumberCustom from '@/components/InputNumberCustom.vue';
import ProjectViewSection from '@/components/project-management/ProjectViewSection';
import BaseUploader from '@/components/common/BaseUploader.vue';
import BaseDatepicker from '@/components/common/BaseDatepicker.vue';
import Editor from '@/components/common/Editor.vue';

import { CHAIN_INFO, SUPPORTED_CHAIN_IDS, TOKEN_SYMBOLS } from '@/constants/chains.constant';
import { CHAIN_ID, JSON_SAMPLE_URL, WHITELIST_SAMPLE_URL } from '@/env';
import ErrorMessage from '@/components/common/ErrorMessage.vue';
import { compare, BigNumber } from '@/utils/bignumber.util';
import { editProject } from '@/services';
import UtilNotify from '@/utils/notify.util';
import { mapGetters, mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
const generalSection = [
  {
    key: 'project_img',
    title: 'PROJECT_IMG_UPLOAD_LABEL',
    scopedSlots: { value: 'project_img' },
  },
  {
    key: 'project_thumbnail',
    title: 'PROJECT_LISTING_IMG_LABEL',
    scopedSlots: { value: 'project_thumbnail' },
  },
  {
    key: 'name',
    title: 'PROJECT_NAME_LABEL',
    scopedSlots: { value: 'name', title: 'label_name' },
  },
  {
    key: 'name_ja',
    title: 'PROJECT_NAME_LABEL',
    scopedSlots: { value: 'name_ja', title: 'label_name_ja' },
  },
  {
    key: 'description',
    title: 'PROJECT_DESCRIPTION_LABEL',
    scopedSlots: { value: 'description', title: 'label_description' },
  },
  {
    key: 'description_ja',
    title: 'PROJECT_DESCRIPTION_LABEL',
    scopedSlots: { value: 'description_ja', title: 'label_description_ja' },
  },
  {
    key: 'chain',
    title: 'PROJECT_CHAIN_LABEL',
    scopedSlots: { value: 'chain' },
  },
  {
    key: 'credit_payment',
    title: 'PROJECT_PAYMENT_LABEL',
    scopedSlots: { value: 'credit_payment' },
  },
  {
    key: 'symbol',
    title: 'PROJECT_SYMBOL_LABEL',
    scopedSlots: { value: 'symbol' },
  },
  {
    key: 'img',
    title: 'PROJECT_NFT_IMG_UPLOAD_LABEL',
    scopedSlots: {
      value: 'img',
    },
  },
  {
    key: 'json',
    title: 'PROJECT_CSV_UPLOAD_LABEL',
    scopedSlots: {
      value: 'json',
    },
  },
  {
    key: 'website',
    title: 'PROJECT_WEBSITE_LABEL',
    scopedSlots: {
      value: 'website',
    },
  },
  {
    key: 'twitter',
    title: 'PROJECT_TWITTER_LABEL',
    scopedSlots: { value: 'twitter' },
  },
  {
    key: 'address',
    title: 'PROJECT_OWNER_ADDRESS_LABEL',
    scopedSlots: { value: 'address' },
  },
];

const settingSection = [
  {
    key: 'reveal',
    title: 'PROJECT_REVEAL_LABEL',
    scopedSlots: { value: 'reveal' },
  },
  {
    key: 'presale_remaining',
    title: 'PROJECT_PRESALE_REMAINING_LABEL',
    scopedSlots: { value: 'presale_remaining' },
  },
];

const presaleSection = [
  {
    key: 'presale_start',
    title: 'PROJECT_PRESALE_START_LABEL',
    scopedSlots: { value: 'start' },
  },
  {
    key: 'presale_end',
    title: 'PROJECT_PRESALE_END_LABEL',
    scopedSlots: { value: 'end' },
  },
  {
    key: 'presale_amount',
    title: 'PROJECT_PRESALE_AMOUNT_LABEL',
    scopedSlots: { value: 'amount' },
  },
  {
    key: 'presale_target',
    title: 'PROJECT_PRESALE_TARGET_LABEL',
    scopedSlots: { value: 'presale_target' },
  },
  {
    key: 'presale_price',
    title: 'PROJECT_PRESALE_PRICE_LABEL',
    scopedSlots: { value: 'price' },
  },
  {
    key: 'presale_whitelist',
    title: 'PROJECT_PRESALE_WHITELIST_LABEL',
    scopedSlots: { value: 'whitelist' },
  },
  {
    key: 'presale_max_purchasability',
    title: 'PROJECT_PRESALE_MAX_PURCHASE_LABEL',
    scopedSlots: { value: 'max_purchasability' },
  },
];
const presaleStatistics = [
  {
    key: 'presale_sold',
    title: 'PROJECT_PRESALE_SOLD_LABEL',
    scopedSlots: { value: 'sold' },
  },
  {
    key: 'presale_revenue',
    title: 'PROJECT_PRESALE_REVENUE_LABEL',
    scopedSlots: { value: 'revenue' },
  },
  {
    key: 'presale_available',
    title: 'PROJECT_PRESALE_AVAILABLE_LABEL',
    scopedSlots: { value: 'available' },
  },
];
const publicSaleSection = [
  {
    key: 'public_sale_start',
    title: 'PROJECT_PUBSALE_START_LABEL',
    scopedSlots: { value: 'start' },
  },
  {
    key: 'public_sale_end',
    title: 'PROJECT_PUBSALE_END_LABEL',
    scopedSlots: { value: 'end' },
  },
  {
    key: 'public_sale_amount',
    title: 'PROJECT_PUBSALE_AMOUNT_LABEL',
    scopedSlots: { value: 'amount' },
  },
  {
    key: 'publicsale_target',
    title: 'PROJECT_PUBSALE_TARGET_LABEL',
    scopedSlots: { value: 'publicsale_target' },
  },
  {
    key: 'public_sale_price',
    title: 'PROJECT_PUBSALE_PRICE_LABEL',
    scopedSlots: { value: 'price' },
  },
  {
    key: 'public_sale_max_purchasability',
    title: 'PROJECT_PUBSALE_MAX_PURCHASE_LABEL',
    scopedSlots: { value: 'max_purchasability' },
  },
];
const publicSaleStatistics = [
  {
    key: 'public_sale_sold',
    title: 'PROJECT_PUBSALE_SOLD_LABEL',
    scopedSlots: { value: 'sold' },
  },
  {
    key: 'public_sale_revenue',
    title: 'PROJECT_PUBSALE_REVENUE_LABEL',
    scopedSlots: { value: 'revenue' },
  },
  {
    key: 'public_sale_available',
    title: 'PROJECT_PUBSALE_AVAILABLE_LABEL',
    scopedSlots: { value: 'available' },
  },
];
const feeSection = [
  {
    key: 'fee_ratio',
    title: 'PROJECT_FEE_LABEL',
    scopedSlots: { value: 'fee' },
  },
  {
    key: 'dev_fee_rate',
    title: 'PROJECT_REFERRAL_FEE_LABEL',
    scopedSlots: { value: 'dev_fee_rate' },
  },
];
export default {
  name: 'ProjectDetailPage',
  props: {},
  components: {
    Editor,
    ProjectViewSection,
    InputNumberCustom,
    BaseUploader,
    BaseDatepicker,
    ErrorMessage,
  },
  data() {
    return {
      generalSection,
      presaleSection,
      presaleStatistics,
      publicSaleSection,
      publicSaleStatistics,
      feeSection,
      settingSection,
      loading: false,
      form: {
        owner_address: '',
        banner: null,
        thumbnail: null,
        contract_type: 721,
        name: '',
        name_ja: '',
        description: '',
        description_ja: '',
        chain_id: CHAIN_ID,
        credit_payment: false,
        token_address: null,
        symbol: 'INO',
        images: null,
        json: null,
        website_url: '',
        twitter_url: '',
        presale_start_time: null,
        presale_end_time: null,
        presale_price: '',
        presale_amount: '',
        presale_target: '',
        whitelist: null,
        presale_max_purchase: '',
        publicsale_start_time: null,
        publicsale_end_time: null,
        publicsale_price: '',
        publicsale_amount: '',
        publicsale_target: '',
        publicsale_max_purchase: '',
        fee: '2.0',
        dev_fee_rate: '0.2',
        can_reveal: false,
        reveal_thumbnail: null,
        is_presale_tba: false,
        is_publicsale_tba: false,
        is_presale_price_tba: false,
        is_publicsale_price_tba: false,
        transfer_unsold_disabled: false,
        target: '',
      },
      JSON_SAMPLE_URL,
      WHITELIST_SAMPLE_URL,
    };
  },
  computed: {
    ...mapGetters({
      userRole: 'wallet/loggedUserRole',
      account: 'wallet/account',
    }),
    ...mapState({
      locale: (state) => state?.setting.locale,
      projectDetailMap: (state) => state.projects.projectDetailMap,
      connectedChainId: (state) => state.wallet?.provider?.chainId || -1,
    }),
    presaleSectionFiltered() {
      let result = presaleSection;
      if (!this.form.transfer_unsold_disabled) {
        result = result.filter((el) => el.key !== 'presale_target');
      }
      if (!Number(this.form.presale_amount)) {
        result = result.filter((item) => item.key === 'presale_amount');
      }
      return result;
    },
    publicSaleSectionFiltered() {
      let result = publicSaleSection;
      if (!this.form.transfer_unsold_disabled) {
        result = result.filter((el) => el.key !== 'publicsale_target');
      }
      if (!Number(this.form.publicsale_amount)) {
        result = result.filter((item) => item.key === 'public_sale_amount');
      }
      return result;
    },
    projectDetail() {
      const { id } = this.$route.params;
      console.debug({ id, projectDetailMap: this.projectDetailMap });
      if (id) {
        const data = this.projectDetailMap[id];
        console.debug({ data });
        return data;
      }
      return {};
    },
    chains() {
      let chains = [];
      SUPPORTED_CHAIN_IDS.forEach((el) => {
        const chainData = CHAIN_INFO[el];
        chains.push({
          key: `${chainData?.name} - ${chainData.nativeCurrency.symbol}`,
          value: `${el}`,
        });
        if (Array.isArray(chainData.acceptedTokens)) {
          chainData.acceptedTokens.forEach((item) => {
            chains.push({
              key: `${chainData?.name} - ${item.symbol}`,
              value: `${el}##${item.tokenAddress}`,
            });
          });
        }
      });
      return chains;
    },
    symbol() {
      return this.form.token_address
        ? TOKEN_SYMBOLS[this.form.chain_id][this.form.token_address]
        : CHAIN_INFO[this.form.chain_id].nativeCurrency.symbol;
    },
    isPreSaleTimeInValid() {
      if (this.form.presale_start_time && this.form.presale_end_time) {
        return compare('gte')(this.form.presale_start_time)(this.form.presale_end_time);
      }
      return false;
    },
    preSaleTimeError() {
      if (this.isPreSaleTimeInValid) return 'PROJECT_PRESALE_START_TIME_ERROR';
      return '';
    },
    isPublicSaleTimeInValid() {
      if (this.form.publicsale_start_time && this.form.publicsale_end_time) {
        return compare('gte')(this.form.publicsale_start_time)(this.form.publicsale_end_time);
      }
      return false;
    },
    isPublicSaleStartTimeNotAfterPresaleStartTime() {
      if (this.form.publicsale_start_time && this.form.presale_start_time) {
        return compare('lte')(this.form.publicsale_start_time)(this.form.presale_start_time);
      }

      return false;
    },
    isPublicSaleEndTimeNotAfterPresaleEndTime() {
      if (this.form.publicsale_end_time && this.form.presale_end_time) {
        return compare('lte')(this.form.publicsale_end_time)(this.form.presale_end_time);
      }
      return false;
    },
    publicSaleTimeError() {
      if (this.isPublicSaleTimeInValid) return 'PROJECT_PUBSALE_START_TIME_ERROR';
      if (this.isPublicSaleStartTimeNotAfterPresaleStartTime) {
        return 'PROJECT_PUBSALE_START_OVERLAP_TIME_ERROR';
      }
      if (this.isPublicSaleEndTimeNotAfterPresaleEndTime) {
        return 'PROJECT_PUBSALE_END_OVERLAP_TIME_ERROR';
      }
      return '';
    },
    totalAmount() {
      if (this.form.presale_amount || this.form.publicsale_amount) {
        return Number(this.form.presale_amount || 0) + Number(this.form.publicsale_amount || 0);
      }
      return 0;
    },
  },
  watch: {
    projectDetail(detail) {
      const clonedFormData = { ...this.form };
      console.debug({ detail });
      if (detail) {
        Object.keys(clonedFormData).forEach((key) => {
          if (!['banner', 'images', 'json', 'reveal_thumbnail', 'whitelist'].includes(key)) {
            if (
              [
                'presale_start_time',
                'publicsale_end_time',
                'publicsale_start_time',
                'presale_end_time',
              ].includes(key)
            ) {
              if (detail[key]) {
                clonedFormData[key] = dayjs(detail[key]).unix() * 1000;
              }
            } else {
              clonedFormData[key] = detail[key];
            }
          }
        });
        this.form = {
          ...clonedFormData,
        };
        this.form.credit_payment = this.projectDetail.payment === 1;
      }
    },
  },
  created() {
    this.fetchProjectDetailById();
  },

  methods: {
    onChainChange(value) {
      const [chainId, tokenAddress] = value.split('##');
      this.form.chain_id = chainId;
      this.form.token_address = tokenAddress || null;
    },
    getLink(link) {
      if (link.includes('https://') || link.includes('http://')) {
        return link;
      } else {
        return `https://${link}`;
      }
    },
    ...mapActions({
      fetchProjectDetail: 'projects/fetchProjectDetail',
    }),
    async fetchProjectDetailById() {
      try {
        const { id } = this.$route.params;
        await this.fetchProjectDetail(id);
      } catch (error) {
        console.debug({ error });
        UtilNotify.error(this.$notify, error);
        this.$router.push('/manage/projects');
      }
    },

    async onSubmitForm() {
      try {
        const isValid = await this.$refs.observer.validate();
        console.debug({
          preSaleTimeError: this.preSaleTimeError,
          publicSaleTimeError: this.publicSaleTimeError,
          isValid,
        });
        if (!isValid || this.preSaleTimeError || this.publicSaleTimeError) return;
        if (this.loading) return;
        this.loading = true;

        const form = {
          ...this.form,
          banner: this.form.banner?.file,
          symbol: this.form.symbol || 'T2WEB',
          thumbnail: this.form.thumbnail?.file,
          images: this.form.images?.file,
          json: this.form.json?.file,
          whitelist: this.form.whitelist?.file,
          presale_start_time: this.form.presale_start_time
            ? new BigNumber(this.form.presale_start_time).div(1000).toNumber()
            : null,
          presale_end_time: this.form.presale_end_time
            ? new BigNumber(this.form.presale_end_time).div(1000).toNumber()
            : null,
          publicsale_start_time: this.form.publicsale_start_time
            ? new BigNumber(this.form.publicsale_start_time).div(1000).toNumber()
            : null,
          publicsale_end_time: this.form.publicsale_end_time
            ? new BigNumber(this.form.publicsale_end_time).div(1000).toNumber()
            : null,
          website_url: this.getLink(this.form.website_url),
          twitter_url: this.getLink(this.form.twitter_url),
          reveal_thumbnail: this.form.can_reveal ? this.form.reveal_thumbnail?.file : undefined,
          presale_amount: Number(this.form.presale_amount || 0),
          publicsale_amount: Number(this.form.publicsale_amount || 0),
          publicsale_max_purchase: Number(this.form.publicsale_max_purchase || 0),
          presale_max_purchase: Number(this.form.presale_max_purchase || 0),
        };
        const payload = new FormData();
        for (const property in form) {
          const data = form[property];
          if (data || data === 0) {
            payload.append(`${property}`, form[property]);
          }
        }
        const data = await editProject(this.projectDetail.id, payload);
        this.loading = false;

        if (data) {
          UtilNotify.success(this.$notify, { message: 'PROJECT_EDIT_SUCCESSFUL_NOTIFICATION' });
          this.$router.push('/manage/projects');
        }
      } catch (error) {
        this.loading = false;

        console.error(error);
        UtilNotify.error(this.$notify, error);
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
::v-deep .input-number-custom input {
  @apply px-2 rounded-none;
  @apply bg-white text-black text-lg;
  @apply h-8;
  @apply disabled:cursor-not-allowed;
}

::v-deep .ant-input {
  @apply border-none rounded-sm text-black text-lg;
}

::v-deep .ant-select {
  @apply font-semibold text-black;
  &-selection {
    background-color: #fff;
    @apply text-lg font-semibold;
    border: none;
    border-radius: 0px;
    &__rendered {
      // .ant-select-selection-selected-value {
      //   @apply relative left-1/2;
      //   transform: translateX(-50%);
      // }
    }
  }
}

::v-deep .ant-checkbox-wrapper {
  @apply text-white text-xl;
  .ant-checkbox {
    span {
      @apply text-xl text-white;
    }
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      @apply border-white bg-white;
      &::after {
        @apply border-primary;
      }
    }
    &::after {
      @apply border border-solid border-primary;
    }
  }
}

.w-150px {
  width: 150px;
}
</style>
